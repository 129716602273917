/*
 * @file Сервис взаимодействия
 * @version 2022.2.6
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */
import tool from '../tool';
import { Game } from '../../model/Game';
import { IGameDto } from '../../model/GameDto';
import { Mapper } from '../mapper';

const service = {
  // region Публичные функции
  async getGame(apiUrl: string): Promise<Game | undefined> {
    const resultPromise = tool.get<IGameDto>(apiUrl, '/game', []);
    return resultPromise.then((response: IGameDto | undefined): (Game | undefined) => {
      if (!response) return;
      return Mapper.fromGame(response);
    });
  },

  async gameTurn(apiUrl: string, gameGuid: string, witchGuid: string): Promise<Game | undefined> {
    const resultPromise = tool.get<IGameDto>(apiUrl, '/turn', [
      {name: "gameGuid", value: gameGuid},
      {name: "witchGuid", value: witchGuid},
    ]);
    return resultPromise.then((response: IGameDto | undefined): (Game | undefined) => {
      if (!response) return;
      return Mapper.fromGame(response);
    });
  },

  async getVersion(apiUrl: string): Promise<string | undefined> {
    return tool.get<string>(apiUrl, '/version', []);
  }
  // endregion
};

export default service;
