/**
 * @file Корневой компонент
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */

import './App.css';
import IntroPage from '../IntroPage/IntroPage';
import GamePage from '../GamePage/GamePage';
import apiStore, { EGameStage } from '../../store/apiStore/apiStore';
import { observer } from 'mobx-react';

type AppProps = {
  onEnterGame?: () => void,
};

const App: React.FC<AppProps> = observer((props) => {
  let content = <></>;
  switch (apiStore.gameStage) {
    case EGameStage.Start: {
      content = <IntroPage onEnterGame={() => {
        apiStore.startNewGame();
      }}/>;
      break;
    }
    case EGameStage.Process: {
      content = <GamePage onExitGame={() => apiStore.setGameStage(EGameStage.Start)}/>;
      break;
    }
  }
  return (
    <>
      <div className="App">
        {content}
      </div>
    </>
  );
});

export default App;
