/**
 * @file Страница игры
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */

import _ from 'lodash';

// @ts-ignore
import witch from '../../resources/witch.svg';
import './GamePage.css';
import { Witch } from '../../model/Witch';
import apiStore from '../../store/apiStore/apiStore';
import { useState } from 'react';

type GameProps = {
  onExitGame: () => void,
};

const GamePage: React.FC<GameProps> = (props) => {
  const {onExitGame} = props;
  const [state, setState] = useState(0);
  const [url, setUrl] = useState(apiStore.apiUrl);

  const setNewUrl = (newUrl: string) => {
    if (url === newUrl) return;
    setUrl(newUrl);
    apiStore.setApiUrl(newUrl);
  };

  const clickAtWitch = (guid: string) => {
    apiStore.turn(guid)
      .then((result) => {
        setState(result ? 1 : 2);
        setTimeout(() => {
          setState(0);
        }, 300);
      });
  };

  const clickAtMockMode = (checked: boolean) => {
    apiStore.tryToSetMockMode(checked);
  }

  return (
    <header className="App-header">
      <div className='game'>
        <div className="small"><h2>Правила игры</h2>Угадываем, какая из ведьм настоящая. За каждый промах теряем 1 балл.
          За угадывание получаем столько баллов, сколько вариантов в задании
        </div>
        <div className="small">Базовая реализация (по умолчанию) использует эмуляцию сервера, но можно подключить свой
          сервер для обслуживания игры!
        </div>
        <div className={`state${state}`}>Баллы: {apiStore.currentGame?.score}</div>
        <div className="witch-row">
          {
            _.map(apiStore.currentGame?.witches, (item: Witch, index: number) => {
              const left = item.position * 100;
              const top = 100;
              return (
                <div key={index} className='witch' style={{left, top}}>
                  <img src={witch} alt={`witch-${index}`} onClick={() => clickAtWitch(item.guid)}/>
                </div>
              )
            })
          }
        </div>
        <div className="small">Версия: {apiStore.version}</div>
      </div>
      <h1 onClick={() => onExitGame()}> Стоп игра! </h1>
      <div>
        <label htmlFor='hasServer'>У меня, кажется, есть свой сервер!</label>
        <input
          type='checkbox'
          id='hasServer'
          checked={!apiStore.mockMode}
          value='1'
          onChange={(e) => clickAtMockMode(e.target.checked)}
        />
      </div>
      <div className="small">Свой сервис:
        <ul>
          <li>реализация <a href="/swagger.json">swagger</a></li>
          <li>хостинг
            <input
              type='text'
              value={url}
              onChange={(e) => setNewUrl(e.target.value)}/></li>
          <li>прием CORS от ooya.ga</li>
        </ul>
      </div>

    </header>
  );
}

export default GamePage;
