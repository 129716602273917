/**
 * @file Утилиты
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */
import axios from 'axios';
import _ from 'lodash';

export type ParamGet = {
  name: string,
  value: string,
};

const tool = {
  // region функции
  // метод взаимодействия с REST-сервисом
  get<TResult>(
    apiUrl: string,
    method: string,
    args: ParamGet[],
  ): Promise<TResult | undefined> {
    const url = apiUrl + method;
    const requestConfig = {
      params: _.fromPairs(args.map((v) => [v.name, v.value]))
    };
    return axios
      .get<TResult>(
        `${url}`,
        requestConfig,
      )
      .then((response) => {
        const {data} = response;
        return data;
      })
      .catch((err) => {
        console.warn(err);
        throw err;
      });
  },
  //endregion

};

export default tool;
