/*
 * @file Класс
 * @version 2022.2.6
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */
import { Game } from '../../model/Game';
import { Witch } from '../../model/Witch';
import _ from 'lodash';

export type ServerWitch = Witch & {
  flagReal: boolean,
};

const mockService = {
  games: [] as Game[],
  score: 0,

  // region Публичные функции
  async getGame(): Promise<Game | undefined> {
    this.score = 0;
    return this.h_restartGame();
  },

  async gameTurn(gameGuid: string, witchGuid: string): Promise<Game | undefined> {
    const game = _.find(this.games, (item) => item.guid === gameGuid);
    if (!game) return;
    const witch: ServerWitch = _.find(game.witches, (item) => item.guid === witchGuid) as ServerWitch;
    if (!witch) return;
    if (witch.flagReal) {
      this.games = _.remove(this.games, game);
      this.score += game.witches.length;
      return this.h_restartGame();
    }
    this.score -= 1;
    return {...game, score: this.score};
  },

  async getVersion(): Promise<string | undefined> {
    return Promise.resolve('(без бэкенда)');
  },
  // endregion

  // region Вспомогательные функции
  h_restartGame(): Game {
    const max = 5;
    const min = 2;
    const stored = this.h_createWitches(min + Math.floor(Math.random() * (max - min + 1)));
    const game = {
      guid: Date.now().toString(10),
      witches: stored,
      score: this.score,
    };
    this.games.push(game);
    return game;
  },

  h_createWitches(count: number): ServerWitch[] {
    let ar: ServerWitch[] = [];
    const dt = Date.now().toString(10);
    for (let ii = 0; ii < count; ii += 1) {
      ar.push({
        guid: dt + '-' + ii, position: ii, flagReal: false,
      });
    }
    // выборы настоящей ведьмы
    const iElected = Math.floor(Math.random() * count);
    const iIndex = iElected >= ar.length ? ar.length - 1 : iElected; // перестраховка
    ar[iIndex].flagReal = true;
    return ar;
  }
  // endregion

};

export default mockService;
