/**
 * @file Страница приветствия
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */

// @ts-ignore
import witch from '../../resources/witch.svg';
import './IntroPage.css';
import apiStore from '../../store/apiStore/apiStore';

type IntroProps = {
    onEnterGame: () => void,
};

const IntroPage: React.FC<IntroProps> = (props) => {
    const {onEnterGame} = props;
    return (
        <header className="App-header">
            <img src={witch} className="App-logo" alt="logo" />
            <h1 onClick={() => onEnterGame()}> Начать играть! </h1>
          {apiStore.errorState && <h4 className="error">{apiStore.errorState}</h4>}
        </header>
    );
}

export default IntroPage;
