/**
 * @file Хранилище для источника данных
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */

import { action, makeObservable, observable } from 'mobx';
import { Game } from '../../model/Game';
import service from './apiStore.service';
import mockService from './mockApiStore.service';

export enum EGameStage {
  Start = 0,
  Process = 1
}

class ApiStore {
  // region observable properties
  mockMode: boolean = true;
  gameStage: EGameStage = EGameStage.Start;
  version: string = "";
  currentGame: Game | undefined;
  errorState: string | undefined;

  apiUrl: string | undefined;

  // endregion


  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL_LOCAL; // REACT_APP_API_URL;

    makeObservable(this, {
      /* observable properties */
      currentGame: observable,
      mockMode: observable,
      apiUrl: observable,
      version: observable,
      errorState: observable,
      gameStage: observable,
      /* actions */
      setCurrentGame: action.bound,
      setApiUrl: action.bound,
      setMockMode: action.bound,
      setVersion: action.bound,
      setErrorState: action.bound,
      setGameStage: action.bound,
    });
    this.backgroundLoad();
  }

  // region public methods
  async backgroundLoad(): Promise<void> {
    if (this.mockMode || !this.apiUrl) {
      // mock mode
      const p1 = mockService.getVersion()
        .then((version) => {
          if (version) this.setVersion(version);
        });
      const p2 = mockService.getGame()
        .then((game) => {
          if (game) this.setCurrentGame(game);
        });
      const promise = Promise.all([p1, p2]);
      return promise.then(() => {
      });
    } else {
      // api mode
      const p1 = service.getVersion(this.apiUrl)
        .then((version) => version && this.setVersion(version));
      const p2 = service.getGame(this.apiUrl)
        .then((game) => game && this.setCurrentGame(game));
      const promise = Promise.all([p1, p2]);
      return promise.then(() => {
      });
    }
  }

  async turn(witchGuid: string): Promise<boolean> {
    if (!this.currentGame) return Promise.resolve(false);
    if (!this.currentGame?.guid) return Promise.resolve(false);
    let promise;
    if (this.mockMode || !this.apiUrl) {
      // mock mode
      promise = mockService.gameTurn(this.currentGame.guid, witchGuid);
    } else {
      promise = service.gameTurn(this.apiUrl, this.currentGame?.guid, witchGuid);
    }

    return promise.then((game) => {
      if (!game) return false;
      const result = (game?.guid !== this.currentGame?.guid);
      this.setCurrentGame(game);
      return result;
    });
  }

  // endregion

  // region setters
  setCurrentGame(game: Game) {
    this.currentGame = game;
  }

  setMockMode(mockMode: boolean) {
    if (mockMode !== this.mockMode) this.mockMode = mockMode;
  }

  setApiUrl(apiUrl: string) {
    if (apiUrl !== this.apiUrl) {
      this.apiUrl = apiUrl;
    }
  }

  setVersion(version: string) {
    if (version && version !== this.version) {
      this.version = version;
    }
  }

  setErrorState(errorState: string) {
    this.errorState = errorState;
    if (errorState) {
      this.gameStage = EGameStage.Start;
    }
  }

  setGameStage(gameStage: EGameStage) {
    if (gameStage === this.gameStage) return;
    this.gameStage = gameStage;
  }

  //endregion
  startNewGame() {
    const promise = (this.mockMode || !this.apiUrl)
      ? mockService.getGame()
      : service.getGame(this.apiUrl);
    promise.then((game) => {
      if (game) {
        this.setCurrentGame(game);
        this.setGameStage(EGameStage.Process)
      }
    });
  }

  tryToSetMockMode(flagEnabled: boolean) {
    if (!flagEnabled || !this.apiUrl) {
      this.setMockMode(true);
      this.setGameStage(EGameStage.Start);
      return;
    }
    service.getVersion(this.apiUrl)
      .then((version) => {
        if (!version) {
          this.setMockMode(true);
          this.setGameStage(EGameStage.Start);
          return;
        }
        this.setVersion(version);
        if (version) {
          this.setMockMode(false);
          this.setGameStage(EGameStage.Start);
        }
      })
      .catch((error) => {
        this.setMockMode(true);
        this.setErrorState(error.message);
        this.setGameStage(EGameStage.Start);
      })
  }
}

export default new ApiStore();
