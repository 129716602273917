/**
 * @file Конвертация объектов DTO в внутренние
 * @version 2022.01.26
 * @author Verevkin S.A.
 * @copyright Verevkin S.A.
 */
import _ from 'lodash';

import { Game } from '../model/Game';
import { IGameDto } from '../model/GameDto';
import { IWitchDto } from '../model/WitchDto';
import { Witch } from '../model/Witch';

export const Mapper = {
  fromGame(gameDto: IGameDto): Game {
    return {
      guid: gameDto.guid,
      score: gameDto.score,
      witches: _.map(gameDto.witches, (witch: IWitchDto) => this.fromWitch(witch)),
    }
  },
  fromWitch(witch: IWitchDto): Witch {
    return {
      position: witch.position,
      guid: witch.guid,
    }
  }
}
